<template>
  <div :style="slidePosition" class="z-6 w-100 h-100">
    <div class="position-absolute text-warning softMatterWrapper "
      style="width:37.5%;left:10%;top:37.5%;transform:translate(0%,-0%)">
      <img src="images/Slide22/2017-Record-Cold-for-canada-TRIUMF.jpg" class="recordCold" style="width:100%" />
    </div>
    <div iv class="position-absolute text-white " style="width:40%;left:55%;top:29%;transform: translateY(-50%)">
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade22" style="opacity:0;">
        2017
      </h3>
      <h6 class="font-vw-2-5 font-w-600 fade22 " style="opacity:0; ">
        RECORD COLD FOR CANADA
      </h6>
    </div>
    <div iv class="position-absolute text-white " style="width:40%;left:55%;top:42%;transform: translateY(-0%)">
      <p class="font-vw-1-35 text-22" style="opacity:0; ">
        How do you unlock the great mystery of why there’s more matter than
        antimatter in the cosmos? Make really, really cold neutrons. The TRIUMF
        Ultra Cold Advanced Neutron source (TUCAN) experiment is using
        quantum-cooling techniques to chill a dense stream of free neutrons to
        just 0.003 of a degree above absolute zero, producing Canada’s first
        ultracold neutrons on November 5, 2017. The neutrons are moving slowly
        enough that physicists can measure whether a neutron has electric
        poles—a result with cosmic implications!
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    //this.startLoop()
  },
  methods: {
    slide22(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade22",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-22",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    recordCold(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 500
        })
        .add({
          targets: ".recordColdWrapper",
          opacity: [0, 1],
          easing: "easeInOutSine",
          duration: 500,
          delay: 1500
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.recordCold(this.innerPosition)

      this.slide22(this.innerPosition)
    }
  }
}
</script>

<style></style>
